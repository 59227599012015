.page-transition-leave {

    position: absolute!important;
    z-index: 2;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    transition: opacity .75s;
    height: 100vh;
    animation: slideOut .75s forwards;
}

.page-transition-enter {
    position: relative;
    z-index: 1;
}

.animate {
    animation-duration: 0.75s; opacity: 0; animation-delay: 0s;
}
.animated {
    opacity: 1;
}


@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-timing-function: ease-in-out
}

@keyframes fadeInGrow {
    0% {
        opacity: 0;
        transform: scale3d(.9,.9,1)
    }

    to {
        opacity: 1;
        transform: scaleX(1)
    }
}

.fadeInGrow {
    animation-name: fadeInGrow;
    animation-timing-function: ease-in-out
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(50px,0,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-timing-function: ease-in-out
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-50px,0,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
    animation-timing-function: ease-in-out
}

@keyframes fadeInLeftSmall {
    0% {
        opacity: 0;
        transform: translate3d(-25px,0,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.fadeInLeftSmall {
    animation-name: fadeInLeftSmall;
    animation-timing-function: ease-in-out
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,25px,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.fadeInUp {
    animation-name: fadeInUp;
    animation-timing-function: ease-in-out
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0,-25px,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.fadeInDown {
    animation-name: fadeInDown;
    animation-timing-function: ease-in-out
}


@keyframes slideIn {
    0% {
        transform: translateY(100%);
        height: 100vh
    }

    to {
        transform: translate(0);
        height: 100vh
    }
}

@keyframes slideInMobile {
    0% {
        transform: translate3d(0,100%,0);
        height: 100vh
    }

    to {
        transform: translateZ(0);
        height: 100vh
    }
}

@keyframes slideOut {
    0% {
        transform: translate(0);
        opacity: 1;
        height: 100vh;
        overflow: hidden
    }

    to {
        transform: translateY(-100%);
        opacity: .5;
        height: 100vh;
        overflow: hidden
    }
}

@keyframes slideInExit {
    0% {
        transform: translate3d(0,-100%,0);
        height: 100vh
    }

    to {
        transform: translateZ(0);
        height: 100vh
    }
}

@keyframes slideInExitMobile {
    0% {
        transform: translate3d(0,-100%,0);
        height: 100vh
    }

    to {
        transform: translateZ(0);
        height: 100vh
    }
}

@keyframes slideOutExit {
    0% {
        transform: translateZ(0);
        opacity: 1;
        height: 100vh;
        overflow: hidden
    }

    to {
        transform: translate3d(0,100%,0);
        opacity: .5;
        height: 100vh;
        overflow: hidden
    }
}
